import axios from '@/util/axios'
export default {
  /**
   * 登陆获取用户真实姓名
   */
  userLogin(data) {
    return axios.get("/user/login", Object.assign({params: data, baseURL: axios.defaults.baseURL, needCookie: true}))
  },
  /**
   * 获取研修活动
   */
  getCourseList(data) {
    return axios.get("/course/selectByPgList", Object.assign({params: data, baseURL: axios.defaults.baseURL}))
  },
  /**
   * 获取研修活动--根据时间排序
   */
  orderByCreateTime(data) {
    return axios.get("/course/orderByCreateTime", Object.assign({params: data, baseURL: axios.defaults.baseURL}))
  },
  /**
   * 获取研修活动--根据热度
   */
  orderByHot(data) {
    return axios.get("/course/orderByHot", Object.assign({params: data, baseURL: axios.defaults.baseURL}))
  },
  /**
   * 获取课程详情
   * @param data
   * @returns {*}
   */
  getCourseDetail(data) {
    return axios.get("/course/selectById", Object.assign({params: data, baseURL: axios.defaults.baseURL}))
  },
  /**
   * 获取研修动态
   */
  getStudyNews(data) {
    return axios.get("/studyNews/selectByPgList", Object.assign({params: data, baseURL: axios.defaults.baseURL}))
  },
  /**
   * 获取研修动态详情
   */
  getStudyNewsDetail(data) {
    return axios.get("/studyNews/read", Object.assign({params: data, baseURL: axios.defaults.baseURL}))
  },
  /**
   * 创建网络发信
   */
  webMessageCreate(data) {
    return axios.post("/webMessage/create", data, Object.assign({baseURL: axios.defaults.baseURL, needCookie: true}))
  },
  /**
   * 更新网络发信
   */
  webMessageUpdate(data) {
    return axios.post("/webMessage/update", data, Object.assign({baseURL: axios.defaults.baseURL, needCookie: true}))
  },
  /**
   * 通过条件查询发信，分页
   */
  selectWebMessage(data) {
    return axios.get("/webMessage/selectByPgList", Object.assign({params: data, baseURL: axios.defaults.baseURL}))
  },
  /**
   * 查询发信详情
   */
  selectWebMessageById(data) {
    return axios.get("/webMessage/selectById", Object.assign({params: data, baseURL: axios.defaults.baseURL, needCookie: true}))
  },
  /**
   * 删除网络发信
   */
  deleteWebMessage(data) {
    return axios.post("/webMessage/delete", data, Object.assign({baseURL: axios.defaults.baseURL, needCookie: true}))
  },
  /**
   * 删除网络发信物理删除
   */
  deleteWebMessageById(data) {
    return axios.post("/webMessage/deleteById", data, Object.assign({baseURL: axios.defaults.baseURL, needCookie: true}))
  },
  /**
   * 消息全部已读
   */
  update_read(data) {
    return axios.post("/webMessage/update-read", data, Object.assign({baseURL: axios.defaults.baseURL, needCookie: true}))
  },
  /**
   * 阅读动态
   */
  readStudyNews(data) {
    return axios.get("/studyNews/read", Object.assign({params:data, baseURL: axios.defaults.baseURL}))
  },
  /**
   * 查看中心简介
   */
  selectCenterIntroduce(data) {
    return axios.get("/centerIntroduce/selectByPgList", Object.assign({params:data, baseURL: axios.defaults.baseURL}))
  },
  /**
   * 查看简介详情
   */
  selectCenterIntroduceById(data) {
    return axios.get("/centerIntroduce/selectById", Object.assign({params:data, baseURL: axios.defaults.baseURL}))
  },
  /**
   * 获取用户信息
   */
  getUserInfo(data, needCookie = true) {
    return axios.get("/user/login", Object.assign({params:data, baseURL: axios.defaults.baseURL, needCookie}))
  },
  /**
   * 更新用户信息
   */
  createSpecial(data) {
    return axios.post("/user/createSpecial", data, Object.assign({baseURL: axios.defaults.baseURL}))
  }
}