import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue')
  },
  {
    path: '/yxActivity',
    name: 'yxActivity',
    component: () => import(/* webpackChunkName: "yxActivity" */ '../views/yxActivity/index.vue')
  },
  {
    path: '/yxActivityDetail/:id',
    name: 'yxActivityDetail',
    component: () => import(/* webpackChunkName: "yxActivity" */ '../views/yxActivity/detail.vue')
  },
  {
    path: '/netTransmit',
    name: 'netTransmit',
    component: () => import(/* webpackChunkName: "netTransmit" */ '../views/netTransmit/index.vue')
  },
  {
    path: '/netTransmitDetail/:id?',
    name: 'netTransmitDetail',
    component: () => import(/* webpackChunkName: "netTransmit" */ '../views/netTransmit/detail.vue')
  },
  {
    path: '/centerIntroduction',
    name: 'centerIntroduction',
    component: () => import(/* webpackChunkName: "centerIntroduction" */ '../views/centerIntroduction/index.vue')
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import(/* webpackChunkName: "userCenter" */ '../views/userCenter/index.vue')
  },
  {
    path: '/netMessage',
    name: 'netMessage',
    component: () => import(/* webpackChunkName: "netMessage" */ '../views/netMessage/index.vue')
  },
  {
    path: '/netMessage/detail/:id?',
    name: 'netMessageDetail',
    component: () => import(/* webpackChunkName: "netMessage" */ '../views/netMessage/detail.vue')
  },
  {
    path: '/yxdongtai',
    name: 'yxdongtai',
    component: () => import(/* webpackChunkName: "yxdongtai" */ '../views/yxdongtai/index.vue')
  },
  {
    path: '/yxdongtai/detail/:id?',
    name: 'yxdongtaiDetail',
    component: () => import(/* webpackChunkName: "dongtaiDetail" */ '../views/yxdongtai/dongtaiDetail.vue')
  },
  {
    path: '/xiaoxi',
    name: 'xiaoxi',
    component: () => import(/* webpackChunkName: "dongtaiDetail" */ '../views/xiaoxi/index.vue')
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: '',
  // base: process.env.BASE_URL,
  routes
})

export default router
